import ProductListingBlockModel from 'Models/Blocks/ProductListingBlock/ProductListingBlockModel.interface';
import { styled } from '../../../Theme/stitches.config';
import { useRef } from 'react';
import ProductCardList from 'Commerce/Molecules/ProductList/ProductCardList';
import InteractableContentLink from 'Shared/Components/Links/InteractableContentLink';
import { applyEditModeAttr } from 'Shared/Common/Helpers';
import BlockHeader from '../BlockHeader';
import LoadMoreContainer from 'DesignComponents/Molecules/LoadMoreContainer/LoadMoreContainer';
import ProductCardModel from 'Models/ProductCard/ProductCardModel.interface';
import useLoadMore from 'Shared/Hooks/useLoadMore';
import { useTranslations } from '../../../context/init-data.context';
import { useBlockData } from '../../../routing/block.context';

function ProductListingBlock() {
  const { heading, productCards, inEditMode, link, pageSize, compactItems } = useBlockData<ProductListingBlockModel>()
  const { categoryLabels } = useTranslations();
  const { list, showMore, loadMore } = useLoadMore<ProductCardModel>(
    productCards,
    pageSize
  );

  const rootRef = useRef<HTMLDivElement>(null);
  return (
    <Root ref={rootRef}>
      <Header>
        <BlockHeader inEditMode={inEditMode} heading={heading} />
        {link?.text && link.href && (
          <InteractableContentLink
            {...applyEditModeAttr(inEditMode && 'Link')}
            href={link.href}
          >
            {link.text}
          </InteractableContentLink>
        )}
      </Header>
      <ProductCardList
        {...applyEditModeAttr(inEditMode && 'Products')}
        items={list} compact={compactItems}
      />
      {showMore && (
        <div>
          <LoadMoreContainer
            loadMore={categoryLabels.loadMore}
            isLoading={false}
            onClick={loadMore}
          />
        </div>
      )}
    </Root>
  );
}

const Root = styled('section', {
  py: '$s300',
  '@bpMin720': {
    py: '$s400',
  },
  '@bpMin1025': {
    py: '$s500',
  },
});

const Header = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  '& a': {
    color: '$emphasisedLinkColor',
    fontFamily: 'fontBold',
  },
});

export default ProductListingBlock;
